
  import { defineComponent, ref, onMounted, computed, reactive } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'finance-funds-request-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('financesFundsRequest'), [
          translate('Finances'),
        ]);
      });

      await store.dispatch(Actions.GET_FINANCES_FUNDS_REQUESTS);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('ID'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('fullName'),
          key: 'fullname',
          sortable: true,
        },
        {
          name: translate('phoneNumber'),
          key: 'phone',
          sortable: false,
        },

        {
          name: translate('amount'),
          key: 'amount',
          sortable: true,
        },
        {
          name: translate('propertyCategory'),
          key: 'propertyCategory',
          sortable: true,
        },
        {
          name: translate('source'),
          key: 'screen',
          sortable: true,
        },
        {
          name: translate('LeadID'),
          key: 'LeadID',
          sortable: true,
        },

        {
          name: translate('isSent'),
          key: 'isSent',
          sortable: true,
        },
        {
          name: translate('createdDate'),
          key: 'createdDate',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const overViewFinancesFundsRequest = (id) => {
        router.push({
          name: 'finances-funds-request-overview',
          params: { id },
        });
      };
      const editFinancesFundsRequest = (id) => {
        router.push({ name: 'finances-funds-request-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_FINANCES_FUNDS_REQUEST_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_FINANCES_FUNDS_REQUESTS);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      const formatDate = (date) => {
        if (new Date(date)) return new Date(date).toISOString().slice(0, 10);
        else return '';
      };

      const excelHeader = reactive({
        id: 'id',
        name: 'name',
        phone: 'phone',
        'property category  ': 'property_category.titleEn',
        amount: 'amount',
        salary: 'salary',
        obligations: 'obligations',
        repaymentPeriod: 'repaymentPeriod',
        LeadID: 'res.LeadID',
        LeadCode: 'res.LeadCode',
        isSent: {
          field: 'isSent',
          callback: (value) => {
            if (value) {
              return 'YES';
            } else return 'NO';
          },
        },
        'created date': {
          field: 'createdAt',
          callback: (value) => {
            if (value) {
              return formatDate(value);
            } else return '';
          },
        },
      });
      store.dispatch(Actions.EXPORT_FINANCES_FUNDS_REQUEST);

      return {
        excelHeader,
        excelData: computed(() => store.getters.exportedFinanceFundsinList),
        tableData: computed(() => store.getters.financesList),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.financesListCount),
        pageChanged,
        goTo,
        overViewFinancesFundsRequest,
        loading,
        can,
        editFinancesFundsRequest,
      };
    },
  });
